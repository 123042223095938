// Generated by Framer (16060ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {QZP4mCj2A: {hover: true}};

const cycleOrder = ["QZP4mCj2A"];

const variantClassNames = {QZP4mCj2A: "framer-v-169jpma"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "QZP4mCj2A", image: IyX_rFiUW = {src: new URL("assets/512/00JVN7mtwJbWEtW68q9FSymWcM.png", import.meta.url).href, srcSet: `${new URL("assets/512/00JVN7mtwJbWEtW68q9FSymWcM.png", import.meta.url).href} 409w, ${new URL("assets/00JVN7mtwJbWEtW68q9FSymWcM.png", import.meta.url).href} 700w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QZP4mCj2A", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5PQic", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-169jpma", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QZP4mCj2A"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"QZP4mCj2A-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 875, intrinsicWidth: 700, pixelHeight: 875, pixelWidth: 700, ...toResponsiveImage(IyX_rFiUW)}} className={"framer-do2prl"} data-framer-name={"team_andreas"} layoutDependency={layoutDependency} layoutId={"IfB08PIlx"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5PQic [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5PQic .framer-3gs7tu { display: block; }", ".framer-5PQic .framer-169jpma { height: 438px; overflow: hidden; position: relative; width: 350px; }", ".framer-5PQic .framer-do2prl { aspect-ratio: 0.8 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 438px); left: 0px; overflow: visible; position: absolute; top: 0px; width: 100%; }", ".framer-5PQic .framer-v-169jpma .framer-169jpma { cursor: pointer; }", ".framer-5PQic.framer-v-169jpma.hover .framer-do2prl { height: var(--framer-aspect-ratio-supported, 624px); left: -75px; right: -74px; top: -93px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 438
 * @framerIntrinsicWidth 350
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ej8riJ3b3":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"IyX_rFiUW":"image"}
 */
const FramerzWXbfkwW1: React.ComponentType<Props> = withCSS(Component, css, "framer-5PQic") as typeof Component;
export default FramerzWXbfkwW1;

FramerzWXbfkwW1.displayName = "team-andreas";

FramerzWXbfkwW1.defaultProps = {height: 438, width: 350};

addPropertyControls(FramerzWXbfkwW1, {IyX_rFiUW: {__defaultAssetReference: "data:framer/asset-reference,00JVN7mtwJbWEtW68q9FSymWcM.png?originalFilename=team-andreas.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerzWXbfkwW1, [])